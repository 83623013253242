<script setup lang="ts">
  import type { QuotedPlanVm } from '~/models/quoting/QuotedPlan'
  import { EngineStore } from '~/stores/engine'
  import { ProfileStore } from '~/stores/profile'
  import {PlanType} from "~/generated/api-clients-generated";
  import {SessionStore} from "~/stores/session";

  const props = defineProps<{
    id: string
    quote: QuotedPlanVm
  }>()

  const engine = EngineStore.use()
  const profile = ProfileStore.use()
  const session = SessionStore.use()
  const emptyState = computed(() => engine.isEmptyState)

  const { hydratedTags } = await PlanTags.use()
  const { getComponentContent, hydratePlaceholders } = Cms.use()
  const { content } = await getComponentContent('QuoteCard')

  const tags = computed(() => _compact(props.quote.planAttributes.map(attr => hydratedTags.value[attr])))
  
  const emptyStateText = computed(() => {

    switch (true) {
      case session.planType === PlanType.PDP:
        return content.value?.ext.empty_plan_fit_text_pdp.replace('{{countyName}}',  profile.location.countyName)
      case session.planType === PlanType.MAPD:
        return content.value?.ext.empty_plan_fit_mapd.replace('{{countyName}}',  profile.location.countyName)
      default:
        return content.content.value?.ext.empty_plan_fit_text.replace('{{countyName}}',  profile.location.countyName)
    }
  })

</script>

<template>
  <section class="flex w-full grow flex-col rounded-3xl bg-primary-100/50 px-2 py-4">
    <div class="mb-2 flex items-center gap-x-2 text-xl font-bold">
      <HpIcon name="heart-filled" class="text-primary-600" />
      Why This Plan Fits You
    </div>
    <ul class="flex flex-wrap gap-x-4" v-if="!emptyState && !_isNil(tags) && tags.length > 0">
      <li v-for="tag in tags" class="flex items-center gap-x-1">
        <HpIcon name="check" class="text-success scale-75" />
        <span class="text-lg font-normal">{{ tag }}</span>
      </li>
    </ul>
    <div
      v-else
      class="text-text-light p-4 text-center leading-tight text-gray-800"
      v-html="
            hydratePlaceholders(emptyStateText, {
              countyName: profile.location.countyName
            })
          "
    ></div>
  </section>
</template>

<style scoped></style>
